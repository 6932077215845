import React from "react"
import "../css/pages/academicadvantage.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/SeamlessIframe"

const AcademicAdvantage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Academic Advantage at Beacon Oral & Maxillofacial Surgeons"
        description="Learn more about the 2023 Academic Advantage program at Beacon Oral & Maxillofacial Surgeons"
        lang="en"
      />
      <div className="edu-23">
        <div className="edu-23__section">
          <div className="edu-23__container">
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-desktop-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--desktop"
            />
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-edu-mobile-hero-logo"
              alt="academic advantage logo"
              className="edu-23__hero--mobile"
            />
          </div>
        </div>

        <div className="edu-23__section">
          <div className="edu-23__container">
            <div className="edu-23__even-grid">
              <div>
                <h1>Let's Talk About Your Future</h1>
                <ul>
                  <li>
                    When and how to start planning for your healthcare career
                  </li>
                  <li>Tips on preparing for college and medical school</li>
                  <li>
                    The daily routine of a medical and dental professional
                  </li>
                  <li>What you can expect from a medical career</li>
                  <li>What it’s like to be an entrepreneur and a doctor</li>
                  <li>The many healthcare career paths you can take</li>
                </ul>
                <p>
                  In addition to learning from talented and respected medical
                  professionals, representatives from local colleges and
                  nonprofits will be available to provide more information about
                  college admissions, specific topics, and answer questions.
                </p>
              </div>
              <div className="edu-23__date-time">
                <h2>DATE, TIME, & LOCATION:</h2>

                <p>
                  <span className="edu-23__blue bold">Thursday</span>
                  <br />
                  March 9, 2023
                </p>

                <p>
                  <span className="edu-23__blue bold">
                    Mt Hood Community College
                  </span>
                  <br />
                  26000 SE Stark Street
                  <br />
                  Gresham, Oregon 97030
                </p>

                <p>
                  <span className="edu-23__blue bold">Speakers</span>
                  <br />
                  College Theatre
                </p>

                <p>
                  <span className="edu-23__blue bold">Lunch and Expo</span>
                  <br />
                  Vista Dining Hall
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="edu-23__section">
        <div className="edu-23__container">
          <div className="edu-23__speakers">
            <h2>FEATURED SPEAKERS:</h2>
            <div className="edu-23__even-grid">
              <div>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
              </div>
              <div>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
                <p>
                  <span className="bold">Doctor Name DDS</span>
                  <br />
                  Oral &amp; Maxillofacial Surgeon at
                  <br />
                  Oral Surgery Specialists of Oklahoma
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        <div className="edu-23__apply">
          <h3>Sign Up for Practice Give Away</h3>
        </div>

        <div className="edu-23__form">
          <Seamless src="https://secureform.seamlessdocs.com/f/e279dc881b099657525147fd4c6e56e4?embedded=true" />
        </div>
      </div>
    </Layout>
  )
}

export default AcademicAdvantage
